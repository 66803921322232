// Sends a toast whenever there is a new fill

import currency from "currency.js";
import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  GetOrders200Response,
  InstrumentTypeResponse,
  SideResponse,
  TradeStatusResponse,
} from "../../codegen-api";
import { COLORS } from "../../constants/design/colors";
import { SPACING } from "../../constants/design/spacing";
import { MarketInstrumentContext } from "../../contexts/MarketInstrumentContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useOrder } from "../../hooks/api/order/useOrder";
import { useToast } from "../../hooks/toast";
import useRateLimit from "../../hooks/useRateLimit";
import { useSFX } from "../../hooks/useSFX";
import useFillsWSS from "../../hooks/wss/useFillsWSS";
import { IToast } from "../../interfaces/Toast";
import { getAssetLogo } from "../../utils/asset/assets";
import { getAssetFromSymbol } from "../../utils/instruments";
import { getTradeStatusTitle } from "../../utils/order";
import {
  ToastEnum,
  ToastStatusEnum,
  getToastTitleForInstrument,
} from "../../utils/toast";
import FillBar from "../FillMeter/FillBar";
import { TotalFillSize, TotalFilledBarContainer } from "../TradeForm/style";

type IdToProcessed = {
  [id: string]: boolean;
};

// When 4 toasts is shown consecutively, rate limit for TOAST_LIMITED_WAIT_SECONDS seconds
const MAX_TOASTS_BEFORE_LIMITED = 4;
const TOAST_LIMITED_WAIT_SECONDS = 15;

function FillsToastAlerter() {
  const { addToasts } = useToast();
  const { t } = useTranslation("app", { keyPrefix: "FillsToastAlerter" });
  const { data: orders, mutate: mutateOrders } = useOrder();
  const { trades } = useFillsWSS();

  const processedTradeIds = useRef<IdToProcessed>({});

  const { getMarketPrecision } = useContext(MarketInstrumentContext);
  const { playSound } = useSFX();
  const { notifications } = useContext(SettingsContext);

  const { rateLimited, increaseCount } = useRateLimit(
    MAX_TOASTS_BEFORE_LIMITED,
    1000,
    TOAST_LIMITED_WAIT_SECONDS
  );

  useEffect(() => {
    const newFills =
      trades?.filter((f) => {
        const isMaker = f.liquidity === "maker";
        const isProcessed = processedTradeIds.current[f.trade_id];
        return isMaker && !isProcessed;
      }) || [];

    if (newFills.length) {
      // Show toasts if show fills notifications and not rate limited
      if (notifications.has("fills") && !rateLimited) {
        const toasts: IToast[] = [];
        newFills.forEach((fill) => {
          const {
            amount,
            order_id,
            instrument_name,
            price,
            side,
            trade_status,
            instrument_type,
            is_adl,
          } = fill;

          const matchingOrder = orders?.find((o) => o.order_id === order_id);

          if (!matchingOrder) {
            return;
          }

          const totalFilled = Number(matchingOrder.filled) + Number(amount);
          const asset = getAssetFromSymbol(instrument_name);
          const optionType = matchingOrder.option_type;
          const exp = matchingOrder.expiry
            ? Number(matchingOrder.expiry)
            : undefined;
          const strike = matchingOrder.strike
            ? Number(matchingOrder.strike)
            : undefined;

          // Push toasts
          toasts.push({
            type: ToastEnum.INFO,
            icon: getAssetLogo(asset) || "",
            header: (
              <p>
                {getToastTitleForInstrument(
                  optionType
                    ? InstrumentTypeResponse.Option
                    : InstrumentTypeResponse.Perpetual,
                  instrument_name,
                  exp,
                  strike
                )}
              </p>
            ),
            subheader: (
              <span
                style={{
                  color:
                    side === SideResponse.Buy
                      ? COLORS.positive.one
                      : COLORS.negative.one,
                }}
              >
                {getTradeStatusTitle(side, trade_status, is_adl)}
              </span>
            ),
            stats: [
              {
                label: t("fill_amount"),
                value: (
                  <span
                    style={{
                      color:
                        side === SideResponse.Sell
                          ? COLORS.negative.one
                          : COLORS.positive.one,
                    }}
                  >
                    {amount}
                  </span>
                ),
              },
              {
                label: t("limit_price"),
                value: price
                  ? currency(price, {
                      precision: getMarketPrecision(asset, instrument_type)
                        .price_precision,
                    }).format()
                  : "-",
              },
              {
                label: t("total_filled"),
                value: (
                  <TotalFilledBarContainer>
                    <FillBar
                      percent={
                        (totalFilled / Number(matchingOrder.amount)) * 100
                      }
                      fillColor={
                        side === SideResponse.Buy
                          ? COLORS.positive.one
                          : COLORS.negative.one
                      }
                      style={{
                        marginRight: SPACING.two,
                      }}
                    />
                    <span>{totalFilled.toFixed(2)}</span>
                    <TotalFillSize>
                      &nbsp;/&nbsp;{Number(matchingOrder.amount).toFixed(2)}
                    </TotalFillSize>
                  </TotalFilledBarContainer>
                ),
              },
            ],
            status: ToastStatusEnum.SUCCESS,
          });

          // Also play a sound for each fill
          playSound("order_filled");
        });
        increaseCount(toasts.length);
        addToasts(toasts, 4000);
      }

      const newOrders: GetOrders200Response[] | undefined = orders?.reduce(
        (prev, order) => {
          const matchingFill = newFills.find(
            (f) => f.order_id === order.order_id
          );

          if (matchingFill) {
            // Order status is filled, skip
            if (matchingFill.trade_status === TradeStatusResponse.Filled) {
              return prev;
            }

            // Order is fully filled. Skip
            const filled = Number(order.filled) + Number(matchingFill.amount);
            if (Number(order.amount) - filled <= 0) {
              return prev;
            }

            // Not filled, just update the fills
            return [
              ...prev,
              {
                ...order,
                filled: String(
                  Number(order.filled) + Number(matchingFill.amount)
                ),
              },
            ];
          }
          return [...prev, order];
        },
        [] as GetOrders200Response[]
      );
      mutateOrders(newOrders, { revalidate: false });

      const newProcessed = newFills.reduce(
        (prevFill, fill) => ({
          ...prevFill,
          [fill.trade_id]: true,
        }),
        {} as IdToProcessed
      );

      processedTradeIds.current = {
        ...processedTradeIds.current,
        ...newProcessed,
      };
    }
  }, [
    addToasts,
    getMarketPrecision,
    increaseCount,
    mutateOrders,
    orders,
    playSound,
    rateLimited,
    t,
    trades,
    notifications,
  ]);

  return null;
}

export default FillsToastAlerter;
