import { TFunction } from "i18next";
import {
  OrderStatusResponse,
  OrderTypeResponse,
  SideResponse,
  TradeStatusResponse,
} from "../codegen-api";

export const getTradeStatusTitle = (
  side: SideResponse,
  status?: TradeStatusResponse,
  isADL?: boolean
) => {
  const type = side === SideResponse.Buy ? "Bid" : "Offer";
  let statusText = "";

  if (isADL) {
    return "ADL Position Closed";
  }

  if (status) {
    switch (status) {
      case OrderStatusResponse.Partial:
        statusText = "Partial Fill";
        break;
      case OrderStatusResponse.Filled:
        statusText = "Filled";
        break;
      default:
        break;
    }
  }
  return `${type} ${statusText}`;
};

export function getStopOrderName(
  t: TFunction,
  orderType: OrderTypeResponse,
  isStop?: boolean,
  shortened?: boolean,
  isMobileTradeForm?: boolean
): string {
  const translateKey = "app:utils:order";

  if (!isStop) {
    switch (orderType) {
      case OrderTypeResponse.Limit:
        return t(`${translateKey}:limit`);
      case OrderTypeResponse.Market:
        return isMobileTradeForm
          ? t(`${translateKey}:market_short`)
          : t(`${translateKey}:market`);
    }
  }

  switch (orderType) {
    case OrderTypeResponse.Limit:
      return t(`${translateKey}:stop_limit`);
    default:
      return shortened
        ? t(`${translateKey}:stop_market_short`)
        : t(`${translateKey}:stop_market`);
  }
}
