import styled from "styled-components";
import { COLORS, ICON_COLORS } from "../../../constants/design/colors";
import { SPACING } from "../../../constants/design/spacing";
import { AdlRankResponse } from "../../../codegen-api";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${SPACING.one}px;
`;

export const Bar = styled.div<{ rank?: AdlRankResponse }>`
  width: 2px;
  height: 12px;
  background-color: ${({ rank }) => {
    if (rank === AdlRankResponse.Low) {
      return COLORS.highlight.one;
    }
    if (rank === AdlRankResponse.Medium) {
      return COLORS.system.one;
    }
    if (rank === AdlRankResponse.High) {
      return COLORS.negative.one;
    }
    if (rank === AdlRankResponse.Na) {
      return ICON_COLORS.four;
    }
    return ICON_COLORS.four;
  }};
  border-radius: 2px;
`;
