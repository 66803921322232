import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AdlRankResponse,
  InstrumentTypeResponse,
  MarginTypeResponse,
  SideResponse,
} from "../../../../codegen-api";
import { PortfolioMarketFilter } from "../../../../components/PortfolioSettings/PortfolioMarketFilter";
import { Content } from "../../../../components/PortfolioSettings/style";
import { IModifiedPosition } from "../../../../components/Positions/model";
import { Spinner } from "../../../../components/shared/Spinner";
import { SpinnerContainerWrapper } from "../../../../components/shared/Spinner/style";
import { MarketInstrumentContext } from "../../../../contexts/MarketInstrumentContext";
import { IWSSMark } from "../../../../hooks/wss/model/markPrice";
import {
  IMultiMarkPriceAssetDerivative,
  useMarkPricesWSS,
} from "../../../../hooks/wss/useMarkPriceWSS";
import usePositionsWSS from "../../../../hooks/wss/usePositionsWSS";
import { splitArrayByFilter } from "../../../../utils/array";
import { AssetResponse } from "../../../../utils/asset";
import { calculatePnl, calculatePnlPercent } from "../../../../utils/math";
import { MobileWrapper, PortfolioFilterContainer } from "../style";
import MobilePositions from "./MobilePositions";

interface IMobilePositionsTabProps {
  showFilter?: boolean;
  noPadding?: boolean;
  noAnimation?: boolean;
}

type IModifiedNestedOptionPositions = {
  asset: AssetResponse;
  expiryPositions: {
    [expiry: string]: {
      expiry: string;
      positions: IModifiedPosition[];
    };
  };
};

type IOptionsPositionByAsset = {
  [asset: AssetResponse]: IModifiedNestedOptionPositions;
};

export function MobilePositionsTab({
  showFilter = true,
  noPadding = false,
  noAnimation,
}: IMobilePositionsTabProps) {
  const [filter, setFilter] = useState<InstrumentTypeResponse>();
  const { t } = useTranslation("app", {
    keyPrefix: "pages.PortfolioPage.mobile.MobilePositionsTab",
  });

  const { getMarketPrecision, activePerpMarkets } = useContext(
    MarketInstrumentContext
  );
  const { loading, positions } = usePositionsWSS();

  const uniqueTickers: IMultiMarkPriceAssetDerivative = useMemo(() => {
    const tickers: IMultiMarkPriceAssetDerivative = {};
    positions.forEach((pos) => {
      // If option, check if already subbed
      if (pos.option) {
        const optionTickerExists = Boolean(tickers[pos.instrument_name]);
        if (!optionTickerExists) {
          tickers[pos.instrument_name] = {
            asset: pos.asset,
            derivative: pos.instrument_type,
          };
        }
      } else {
        tickers[pos.instrument_name] = {
          asset: pos.asset,
          derivative: pos.instrument_type,
        };
      }
    });
    return tickers;
  }, [positions]);

  const { instrumentMark } = useMarkPricesWSS(uniqueTickers, 1000);

  const modifiedPositions: IModifiedPosition[] = useMemo(() => {
    let posns = positions;
    if (filter === InstrumentTypeResponse.Option) {
      posns = positions.filter((p) => !!p.option);
    }
    if (filter === InstrumentTypeResponse.Perpetual) {
      posns = positions.filter((p) => !p.option);
    }

    const modifiedPosns = posns.map((p) => {
      const mark: IWSSMark | undefined = instrumentMark[p.instrument_name];

      // Calculate pnl based on mark price from ticker instead
      const markPrice = Number(mark?.mark_price || p.mark_price);
      const positionValue = markPrice * Number(p.amount);
      const entryValue = Number(p.avg_entry_price || 0) * Number(p.amount);
      const unrealized_pnl = calculatePnl(entryValue, positionValue, p.side);
      const roi = calculatePnlPercent(
        unrealized_pnl,
        entryValue,
        Number(
          p.margin_type === MarginTypeResponse.Isolated
            ? p.isolated_margin
            : p.maintenance_margin
        )
      );

      const { amount_precision, price_precision } = getMarketPrecision(
        p.asset,
        p.instrument_type
      );

      const isPrelaunch = activePerpMarkets?.find(
        (market) => market.instrument_id === p.instrument_id
      )?.pre_launch;

      return {
        ...p,
        mark_price: String(markPrice),
        unrealized_pnl: String(unrealized_pnl),
        roi,
        positionValue,
        amountPrecision: amount_precision,
        pricePrecision: price_precision,
        isPrelaunch,
      };
    });

    // Split positions into OPTIONS and PERPS
    const [optionPositions, perpsPositions] = splitArrayByFilter(
      modifiedPosns,
      (pos) => !!pos.option
    );

    // Convert option positions into grouped positions
    const optionsPositionByAsset: IOptionsPositionByAsset =
      optionPositions.reduce((acc, position) => {
        if (!position.option?.expiry) {
          return acc;
        }

        if (!acc[position.asset]) {
          // Initialize empty structure
          acc[position.asset] = {
            asset: position.asset,
            expiryPositions: {},
          };
        }

        if (!acc[position.asset].expiryPositions[position.option.expiry]) {
          acc[position.asset] = {
            asset: position.asset,
            expiryPositions: {
              ...acc[position.asset].expiryPositions,
              [position.option.expiry]: {
                expiry: position.option.expiry,
                positions: [],
              },
            },
          };
        }

        // Push
        acc[position.asset].expiryPositions[
          position.option.expiry
        ].positions.push(position);
        return acc;
      }, {} as IOptionsPositionByAsset);

    return [
      ...perpsPositions,
      ...Object.values(optionsPositionByAsset).map(
        (nestedOptionsPos: IModifiedNestedOptionPositions) => {
          const normalModifiedPos: IModifiedPosition = {
            asset: nestedOptionsPos.asset,
            instrument_type: InstrumentTypeResponse.Option,
            nestedOptionsData: nestedOptionsPos.expiryPositions,

            // All these should be ignored by table
            roi: 0,
            amountPrecision: 0,
            pricePrecision: 0,
            positionValue: 0,
            instrument_id: "0",
            instrument_name: "0",
            // option: {},
            // iv: "0",
            amount: "0",
            side: SideResponse.Buy,
            mark_price: "0",
            avg_entry_price: "0",
            unrealized_pnl: "0",
            maintenance_margin: "0",
            adl_rank: AdlRankResponse.Na,
          };
          return normalModifiedPos;
        }
      ),
    ];
  }, [
    filter,
    getMarketPrecision,
    instrumentMark,
    positions,
    activePerpMarkets,
  ]);

  const simpleModifiedPositions: IModifiedPosition[] = useMemo(
    () =>
      positions.map((p) => {
        const mark: IWSSMark | undefined = instrumentMark[p.instrument_name];

        // Calculate pnl based on mark price from ticker instead
        const markPrice = Number(mark?.mark_price || p.mark_price);
        const positionValue = markPrice * Number(p.amount);
        const entryValue = Number(p.avg_entry_price || 0) * Number(p.amount);
        const unrealized_pnl = calculatePnl(entryValue, positionValue, p.side);
        const roi = calculatePnlPercent(
          unrealized_pnl,
          entryValue,
          Number(
            p.margin_type === MarginTypeResponse.Isolated
              ? p.isolated_margin
              : p.maintenance_margin
          )
        );

        const { amount_precision, price_precision } = getMarketPrecision(
          p.asset,
          p.instrument_type
        );
        return {
          ...p,
          mark_price: String(markPrice),
          unrealized_pnl: String(unrealized_pnl),
          roi,
          positionValue,
          amountPrecision: amount_precision,
          pricePrecision: price_precision,
        };
      }),
    [getMarketPrecision, instrumentMark, positions]
  );

  if (loading) {
    return (
      <SpinnerContainerWrapper>
        <Spinner />
      </SpinnerContainerWrapper>
    );
  }

  return (
    <MobileWrapper noPadding={noPadding}>
      {showFilter ? (
        <PortfolioFilterContainer>
          <PortfolioMarketFilter
            title={t("positions")}
            instrumentType={filter}
            onSetFilter={setFilter}
          />
        </PortfolioFilterContainer>
      ) : null}
      {!loading && modifiedPositions.length < 1 ? (
        <Content>{t("no_history_desc")}</Content>
      ) : (
        <MobilePositions
          positions={modifiedPositions}
          simplePositions={simpleModifiedPositions}
          t={t}
          noAnimation={noAnimation}
        />
      )}
    </MobileWrapper>
  );
}
